<template>
  <div class="MentionRecordDetail">
    <div v-show="symbol!=''">
      <p class="coinNumber">{{obj.withdrawAmount}}{{symbol}}</p>
      <div class="segmentationLine"></div>
      <div class="listView" v-for="(item,index) in titleList" :key="index">
        <p class="title">{{titleList[index]}}</p>
        <p class="result">{{resultList[index]}}</p>
      </div>
    </div>
    <div v-show="symbol==''">
      <p class="coinNumber">
        ¥
        <span v-show="type==1">+</span>
        <span v-show="type==2">-</span>
        {{amount}}
      </p>
      <div class="segmentationLine"></div>
      <div class="listView">
        <p class="title">类型</p>
        <p class="result">
          <span v-show="type==1">充值</span>
          <span v-show="type==2">电费扣除</span>
        </p>
      </div>
      <div class="listView">
        <p class="title">状态</p>
        <p class="result">
          <span v-show="status==1">已成功</span>
        </p>
      </div>
      <div class="listView">
        <p class="title">时间</p>
        <p class="result">{{ctime}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentionRecordDetail",
  data() {
    return {
      coinNumber: "",
      titleList: ["类型", "状态", "提币地址", "手续费", "区块链交易ID", "时间"],
      resultList: ["提币"],
      obj: {},
      symbol: "",
      amount: "",
      type: "",
      status: "",
      ctime: ""
    };
  },
  components: {},
  mounted() {},
  created() {
    this.symbol = this.$route.params.symbol || "";
    this.getParams();
  },
  methods: {
    getParams() {
      if (this.symbol != "") {
        // 取到路由带过来的参数
        let routerParams = this.$route.params;
        // 将数据放在当前组件的数据内
        this.resultList.push(routerParams.status);
        this.resultList.push(routerParams.address);
        this.resultList.push(routerParams.remark1);
        if (routerParams.remark2 == "null") {
          this.resultList.push("--");
        } else {
          this.resultList.push(routerParams.remark2);
        }
        this.resultList.push(routerParams.ctime);
        this.obj = JSON.parse(routerParams.obj);
        console.log(this.obj.withdrawAmount);
      } else {
        this.amount = this.$route.params.amount;
        this.type = this.$route.params.type;
        this.status = this.$route.params.status;
        this.ctime = this.$route.params.ctime;
      }
    }
  }
  //    watch: {
  //     // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
  //       '$route': getParams
  //     }
};
</script>
<style scoped lang="scss">
.MentionRecordDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.coinNumber {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #1677ff;
  text-align: left;
  font-family: "HelveticaCondensedBold", "HelveticaCondensed";
}
.segmentationLine {
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
  border: 0.5px solid #f3f3f3;
}
.listView {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 700;
  color: #93a5b2;
  font-family: "HelveticaCondensedBold", "HelveticaCondensed";
}
.result {
  margin-right: 20px;
  font-size: 13px;
  font-weight: 700;
  color: #333333;
  // flex-grow: 1;
  text-align: right;
  word-wrap: break-word;
  word-break: break-all;
  width: 200px;
  font-family: "HelveticaCondensedBold", "HelveticaCondensed";
}
</style>
